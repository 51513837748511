/* Tooltip.css */
.tooltip {
  position: fixed;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  z-index: 1000;
  white-space: pre-wrap;
  transform: translate(-50%, -120%);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Improve button styling */
button {
  padding: 8px 12px;
  margin: 0 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
}

.blockTable button.edit {
  background-color: #4caf50;
  color: white;
}

button.reset {
  background-color: #f44336;
  color: white;
}
button.update {
  background-color: #5736f4;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dashboard {
  width: 100%;
  height: 100%;
  direction: rtl;
  margin-bottom: 10%;
}

.block {
  background-color: #ddf1ff;
  width: 60%;
  height: auto;
  margin-top: 5%;
  margin: 5% auto 0 auto;
  border-radius: 20px;
  padding: 15px;
}

.blockTable {
  width: 100%;
  border-radius: 20px;
  table-layout: fixed; /* Ensure consistent column widths */
  margin: 3% auto;
}

#onlineTable {
  width: 85%;
}

#onlineTable td {
  direction: ltr;
}

#onlineTable th.type {
  width: 3%;
}

.blockTable#scores {
  border-collapse: separate;
}
.blockTable#scores tr:nth-child(2) td {
  background-color: #400150;
  color: white;
}

.blockTable th,
.blockTable td {
  white-space: nowrap;
  padding: 10px; /* Adjust padding as needed */
  background-color: #e5e5e5;
}

.blockTable th {
  cursor: pointer;
}

.blockTable th,
.blockTable#scores tr:first-child td,
.blockTable#games tr:first-child td {
  font-weight: bold;
  font-size: 13pt;
  color: white;
  background-color: black;
  padding: 10px;
}

.blockTable td {
  background-color: #e5e5e5;
}

/* Ensure all rows have consistent styling */
.blockTable tr:not(:first-child) td {
  background-color: #e5e5e5;
  padding: 10px; /* Ensure consistent padding */
}

.tooltip {
  position: fixed;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  z-index: 1000;
  white-space: pre-wrap;
  transform: translate(-50%, -120%);
}
.editForm {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.editForm h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 18px;
}

.editForm label {
  display: block;
  margin-bottom: 10px;
}

.editForm input[type="text"] {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.editForm button {
  padding: 10px 15px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.editForm button.save {
  background-color: #4caf50;
  color: white;
}

.editForm button.cancel {
  background-color: #f44336;
  color: white;
}

#questions {
  margin-top: 20px;
}

/* Text block styles */
.stats-header {
  background-color: #004080;
  color: white;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.stats-header h2,
.stats-header h3,
.stats-header h4 {
  margin: 0 0 10px 0;
}

.stats-header h2 {
  font-size: 1.5rem;
}

.stats-header h3 {
  font-size: 1.2rem;
}

.stats-header h4 {
  font-size: 1rem;
}

/* Chart container styles */
/* Chart container styles */
.chart-container {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0;
}

.chart-container h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #004080;
}

/* Button styles */
.set-offline-button {
  padding: 8px 12px;
  margin: 10px 0; /* Adjust margin to position the button correctly */
  border: none;
  border-radius: 5px;
  background-color: #f44336; /* Button background color */
  color: white;
  cursor: pointer;
  font-size: 14px; /* Font size for the button */
}

.set-offline-button:hover {
  background-color: #d32f2f; /* Darker shade on hover */
}
